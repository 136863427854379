<template>
    <div class="main-wrapper">
      <div class="logo-img"><img :src="imgL" alt=""></div>
      <div class="select-wrapper">
        <select v-model="choosedProjectEnId">
          <option :value="project.enid" v-for="(project,index) in projectList" :key="index">{{project.name}}</option>
        </select>
        <a class="scroll-down" ><Icon type="ios-train" :size="40"/></a>
        <p class="more-main"><a  @click="goProject(choosedProjectEnId)">进入</a></p>
      </div>
      <span @click="LogOut" class="log-out">登出</span>
      <div class='copyright'>
            <div>SRCC版权所有 © 2019-2021 <a href="https://beian.miit.gov.cn">沪ICP备19045397号-2</a></div>
            <div><img :src="imgB" class="beian-img">沪公安网报备 31011402008424 号</div>
        </div>
    </div>
</template>
<script>
let userToken = localStorage.getItem("current_user_token");
let authData = JSON.parse(localStorage.getItem("authData"));

export default {
  name: "mainFrame",
  components: {
  },
  data() {
    return {
      imgL: process.env.BASE_URL + "img/logo.png",
      imgB: process.env.BASE_URL + "img/Bitmap.png",
      logined: false,
      projectList:[],
      choosedProjectEnId:0
    };
  },
  created() {
    this.getStaticOption();
  },
  mounted() {
  },
  props: {
    
  },
  computed: {
  },
  methods: {
    getStaticOption() {
      this.$axios
        .post(this.$url + "/active_option", {
          userToken: userToken,
          askData: "myValidProjectList",
        })
        .then((response) => {
          if (response.data.stat == "ok") {
            this.projectList = response.data.res_record;
            if(this.projectList.length>0){
              this.choosedProjectEnId = this.projectList[0].enid;
            }else{
              this.$Massage.info("无授权项目信息！");
            }
          } else {
            this.$Massage.error("获取选项数据失败，网络存在问题！");
          }
        });
    },
    LogOut: function () {
      localStorage.clear();
      this.logined = false;
      location.href = "/login";
    },
    goProject(projectEnId){
      if(this.projectList.length>0){
        let project = this.projectList.find(val=>val.enid===projectEnId);
        localStorage.setItem("project", JSON.stringify(project));
      }
      if(authData.barRights.indexOf('roleManage')!==-1||authData.barRights.indexOf('super_all')!==-1){
          location.href = '/manage/projectProgress/'+projectEnId;
      }else if(authData.barRights.indexOf('fileUpload')!==-1){
        if(this.projectList.length>0){
          this.$router.push({'name':'fileUpload'});
        }else{
          this.$Massage.info("无授权项目信息！");
        }
        
      }else if(authData.barRights.indexOf('fileVerify')!==-1){
        if(this.projectList.length>0){
          this.$router.push({'name':'fileVerify'});
        }else{
          this.$Massage.info("无授权项目信息！");
        }
      }else if(authData.barRights.indexOf('fileConfirm')!==-1){
        if(this.projectList.length>0){
          this.$router.push({'name':'fileConfirm'});
        }else{
          this.$Massage.info("无授权项目信息！");
        }
      }else{
        location.href = '/manage/projectProgress/'+projectEnId;
      }

    }
  },
};
</script>

<style scoped>
a {
  color: inherit;
}

a:hover {
  color: inherit;
}

a:active {
  color: inherit;
}

.main-wrapper{
  background: #161616 url("../assets/index.jpg") no-repeat;
  width: 100%;
  height: 10800px;
  padding-top: 30px;
  background-attachment: fixed;
  background-position: center 0;
  display: block;
  position: relative;
  z-index:10000;
}

.select-wrapper{
  position: fixed;
  top:50%;
  left:50%;
  height:200px;
  width:1000px;
  margin-left:-500px;
  margin-top:-100px;
  text-align: center;
}

.select-wrapper select{
  padding: 10px 20px;
  font-size: 32px;
  outline: none;
  border:none;
  border-radius: 5px;
  opacity: 0.8;
}

.log-out{
  position: fixed;
  right:60px;
  top:20px;
  color:#ccc;
  cursor: pointer;
}

.log-out:hover{
  color:#fff;
}

.scroll-down{
    color:#000;
    display: block;
    position: relative;
    top:8%;
    animation: heart 1.3s ease-in-out 2.7s infinite alternate;
}

@keyframes heart{
    from{transform:translate(0,0)}
    to{transform:translate(0,12px)}
}

.more-main{
  background-color: rgba(7, 7, 7, 0.8);
  width:fit-content;
  margin:40px auto 0;
  padding: 8px 40px;
  border-radius: 5px;
  color:#ccc;
  font-size: 24px;
  font-family: '微软雅黑';
}

.more-main:hover{
  background-color: rgb(7, 7, 7,1);
  color:#fff;
}

.copyright{
  position: fixed;
  bottom:20px;
  text-align: center;
  width:100%;
  color:#fff;
}

.beian-img{
  height:12px;
  position: relative;
  top:1px;
  right:2px;
}

.logo-img{
  position: fixed;
  top:20px;
  left:60px;
}

.logo-img img{
  height: 40px;
}

</style>